// import Vue from "vue"
import { nextTick } from "vue";

export default class {
  execute(spec, component) {
    this.populateFields()
  }

  async populateFields() {
    const t = TrelloPowerUp.iframe()

    // Allow time for the component to be present in the DOM.
    const [board, _] = await Promise.all([
      $tpu.urls.preloadBoard(t),
      nextTick()
    ])

    const autoLaunchField = GLib.component.findById("setting_auto_launch");
    const autoLaunchValue = localStorage.getItem($tpu.keys.lclBoardAutoLaunch(board.id))

    switch(autoLaunchValue) {
      case "large": // Flow through
      case "disabled":
        autoLaunchField.action_merge({ value: autoLaunchValue })
        break;
      case "mini": // Flow through
      default:
        autoLaunchField.action_merge({ value: 'mini' })
    }

    const submit = GLib.component.findById("setting_submit");
    submit.action_merge({ disabled: false })
  }
}
